const togglers = document.querySelectorAll('.topstory-toggler');
const images = document.querySelectorAll('.topstory-image');
const titles = document.querySelectorAll('.topstory-title');

/**
 * Swaps topstory articles
 * @param {Event} e
 */
const swap = (toggler) => {
    const id = toggler.dataset.topstory;
    const currentImage = document.getElementById(`topstory-image-${id}`);
    const currentTitle = document.getElementById(`topstory-title-${id}`);
    if (!toggler.classList.contains('active') && currentImage && currentTitle) {
        images.forEach((image) => {
            image.classList.remove('active');
            currentImage.classList.add('active');
        });
        togglers.forEach((el) => {
            el.classList.remove('active');
            toggler.classList.add('active');
        });
        titles.forEach((title) => {
            title.classList.remove('active');
            currentTitle.classList.add('active');
        });
    }
};

togglers.forEach((toggler) => {
    toggler.addEventListener('mouseover', () => {
        swap(toggler);
    });
});
